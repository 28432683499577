import { Button } from "@wbly/ui";
import useBrandHero from "./useBrandHero";
import styles from "./BrandHero.module.css";
import type { BrandHeroProps } from ".";

const { cta } = styles;

export const BrandHeroCta = (props: Partial<BrandHeroProps>) => {
  const { ctaLabel, url, id, cutout, invert } = props;
  // Fire event for cutour and get hangleClick function
  const { handleClick } = useBrandHero({ cutout, invert, id, url });

  return (
    <>
      {ctaLabel && url && (
        <Button
          data-eventid={id}
          className={cta}
          onClick={() => handleClick(id, url)}
        >
          {ctaLabel}
        </Button>
      )}
    </>
  );
};
